import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ItemForm from './../components/KnownFakes/ItemForm';
import ItemList from './../components/KnownFakes/ItemList';
import './KnownFakes.css';

const KnownFakesPage = () => {
  const [items, setItems] = useState([]);
  const [itemToEdit, setItemToEdit] = useState(null);

  const fetchItems = async () => {
    const response = await axios.get('/api/knownfake');
    setItems(response.data);
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const editItem = (item) => {
    setItemToEdit(item);
  };

  const deleteItem = async (id) => {
    await axios.delete(`/api/knownfake/${id}`);
    fetchItems();
  };

  return (
    <div className="App">
      <h1>Fake Item DB</h1>
      <ItemForm fetchItems={fetchItems} itemToEdit={itemToEdit} setItemToEdit={setItemToEdit} />
      <ItemList items={items} editItem={editItem} deleteItem={deleteItem} />
    </div>
  );
};

export default KnownFakesPage;
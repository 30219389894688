import "./../SearchResultsSection.css";
import React from "react";
import { useDispatch } from 'react-redux';
import { cards } from "../utils/analysisCards";
import { IoCheckmarkCircle } from "react-icons/io5";
import { GoAlertFill } from "react-icons/go";
import { IoInformationCircle } from "react-icons/io5";
import { IoConstruct } from "react-icons/io5";
import { IoRemoveCircle } from "react-icons/io5";


import { ModalTypes } from '../../../constants/ModalTypes';
import { openModal } from '../../../store/App/slice';

const Cards = ({ setAnalysisView, mediaAnalysisResults, setFilterString }) => {

  function getCardColorBox(singleCard, mediaAnalysisResults) {
    if (!singleCard.enabled) return 'colored-box gray-box';
    if (singleCard.alwaysValid) return 'colored-box yellow-box';
    const analysisData = mediaAnalysisResults.find(el => el.analysisType === singleCard.title);
    if (analysisData===undefined) return 'colored-box gray-box';
    return analysisData.result.isValid ? 'colored-box green-box': 'colored-box red-box';
  }
  function getCardColorBoxText(singleCard, mediaAnalysisResults) {
    if (!singleCard.enabled) return 'colored-box-text';
    if (singleCard.alwaysValid) return 'colored-box-text';
    const analysisData = mediaAnalysisResults.find(el => el.analysisType === singleCard.title);
    if (analysisData===undefined) return 'colored-box-text'
    return analysisData.result.isValid ? 'colored-box-text': 'colored-box-text red-text'
  }
  function getCardObject(singleCard, mediaAnalysisResults) {
    if (!singleCard.enabled) return <IoConstruct className="colored-box-icon"/>
    const analysisData = mediaAnalysisResults.find(el => el.analysisType === singleCard.title);
    if (analysisData===undefined) return <IoRemoveCircle className="colored-box-icon-gray-icon" size={30}/>
    if (singleCard.alwaysValid) return <IoInformationCircle className="colored-box-icon" size={30}/>
    return analysisData.result.isValid ?
      <IoCheckmarkCircle className="colored-box-icon"/> :
      <GoAlertFill className="colored-box-icon-red-icon" size={20}/>
  }
  function getButtonClass(singleCard, mediaAnalysisResults) {
    if (!singleCard.enabled) return 'green-button';
    if (singleCard.alwaysValid) return 'green-button';
    const analysisData = mediaAnalysisResults.find(el => el.analysisType === singleCard.title);
    if (analysisData===undefined) return 'green-button'
    return analysisData.result.isValid ? 'green-button': 'red-button'
  }
  function getButtonText(singleCard, mediaAnalysisResults) {
    const analysisData = mediaAnalysisResults.find(el => el.analysisType === singleCard.title);
    if (analysisData===undefined && singleCard.notFoundButtonText) return singleCard.notFoundButtonText;
    if (analysisData===undefined || !singleCard.customBottonText) return singleCard.buttonText;
    return analysisData.result.reasons.length > 0 ? analysisData.result.reasons[0] : 'No issue found';
  }

  function setSearchFilter(singleCard, mediaAnalysisResults) {
    const analysisData = mediaAnalysisResults.find(el => el.analysisType === singleCard.title);
    if (analysisData===undefined) return;
    const hasInvalidReason = analysisData?.result?.isValid===false && analysisData.result.reasons?.length > 0;
    if (hasInvalidReason) {
      const invalidReason = analysisData.result.reasons[0];
      if (invalidReason==='Multiple fake indications') {
        setFilterString("label::fakeIndicated")
      } else if (singleCard.title==='Time Validation') {
        setFilterString("label::showDatesOnly")
      }
    }
  }

  const dispatch = useDispatch();

  const openSystemModal = (analysisTitle) => {
    const analysisData = mediaAnalysisResults.find(el => el.analysisType === analysisTitle);
    if (analysisData===undefined) return;
    const modalType = analysisTitle === 'Visual Inspection' ? ModalTypes.VISUAL_INSPECTION :
                      analysisTitle === 'Generative AI' ? ModalTypes.GENERATION_AND_MANIPULATION :
                      analysisTitle === 'Manual Manipulation' ? ModalTypes.GENERATION_AND_MANIPULATION :
                      analysisTitle === 'Metadata Extraction' ? ModalTypes.METADATA_EXTRACTION : null;

    dispatch(
      openModal({
        type: modalType,
        width: 470,
        payload: {
          title: analysisTitle,
          props: {
            mediaAnalysisResults: analysisData,
            analysisName: analysisTitle
          }
        }
      })
    )
  }


  return (
    <div className="cards-container">
      {cards.map((card, index) => (
        <div
          className={getCardColorBox(card, mediaAnalysisResults)}
          key={index}
        >
          {getCardObject(card, mediaAnalysisResults)}
          <h2 className={getCardColorBoxText(card, mediaAnalysisResults)}>
            {card.title}
          </h2>
          <button
            className={getButtonClass(card, mediaAnalysisResults)}
            onClick={() => {
              if (card.isUsingModal) {
                openSystemModal(card.title);
              } else if (card.searchFilterAction) {
                setSearchFilter(card, mediaAnalysisResults);
              } else {
                card.linkAction && setAnalysisView(card.title)
              }
            }}
          >
            {getButtonText(card, mediaAnalysisResults)}
          </button>
        </div>
      ))}
    </div>
  );
};

export default Cards;

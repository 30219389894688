import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Checkbox, FormControlLabel, Card, CardMedia, CardContent, Typography } from '@mui/material';
import AudioPlayer from "./audio";

const FrameSelectorModal = ({ open, handleClose, runOp, frames, audio,
                              selectedFrames, setSelectedFrames, openContextInputModal }) => {
  const [warning, setWarning] = useState('');
  const [audioEnabled, setAudioEnabled] = useState(false);
  const [audioSelection, setAudioSelection] = useState(null);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const selectionLimit = 8;
  const maxAudioDuration = 30;

  useEffect(() => {
    // Reset selected frames when the modal is opened
    if (open) {
      setSelectedFrames([]);
      setWarning('');
      setAudioEnabled(false);
      setAudioSelection(null);
    }
  }, [open, setSelectedFrames]);

  useEffect(() => {
    const audioConditionMet = audioEnabled && (audioSelection !== null && 
      audioSelection.start < audioSelection.end && audioSelection.end - audioSelection.start <= maxAudioDuration);
    const conditionsMet = audioConditionMet || (selectedFrames.length > 0);
    setIsButtonEnabled(conditionsMet);
  }, [selectedFrames, audioSelection, audioEnabled]);

  const handleRun = (frames, audio) => {
    runOp(frames, audio);
    handleClose();
  };

  const handleToggleAudio = () => {
    if (audioEnabled) {
      setAudioEnabled(false);
      setAudioSelection(null);
    } else {
      setAudioEnabled(true);
    }
  };

  const handleToggle = (id) => {
    setSelectedFrames(prev => {
      if (prev.includes(id)) {
        setWarning('');
        return prev.filter(frameId => frameId !== id);
      } else {
        if (prev.length < selectionLimit) {
          setWarning('');
          return [...prev, id];
        } else {
          setWarning(`You can only select up to ${selectionLimit} frames.`);
          return prev;
        }
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedFrames.length === frames.length) {
      setSelectedFrames([]);
    } else {
      const newSelection = frames.slice(0, selectionLimit).map(frame => frame.id);
      setSelectedFrames(newSelection);
      setWarning(newSelection.length < frames.length ? `You can only select up to ${selectionLimit} frames.` : '');
      console.log('Selected frames:', newSelection);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Select Frames&Audio</DialogTitle>
      <DialogContent>
        {warning && <Typography color="error">{warning}</Typography>}
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedFrames.length === frames.length}
              onChange={handleSelectAll}
              indeterminate={selectedFrames.length > 0 && selectedFrames.length < frames.length}
            />
          }
          label="Select All"
        />
        <Grid container spacing={2}>
          {frames.map(frame => (
            <Grid item xs={6} sm={4} md={3} key={frame.id}>
              <Card>
                <CardMedia
                  component="img"
                  alt={frame.path}
                  height="140"
                  image={frame.img}
                />
                <CardContent>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedFrames.includes(frame.id)}
                        onChange={() => handleToggle(frame.id)}
                        disabled={!selectedFrames.includes(frame.id) && selectedFrames.length >= selectionLimit}
                      />
                    }
                  />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        {audio && <FormControlLabel
          control={
            <Checkbox
              checked={audioEnabled}
              onChange={handleToggleAudio}
            />
          }
          label="Audio"
        />}
        {audioEnabled && <AudioPlayer audioData={audio} setAudioSelectionCb={setAudioSelection} maxAudioDuration={maxAudioDuration}/>}
      </DialogContent>
      <DialogActions>
        <Button onClick={openContextInputModal}>Add Context</Button>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={() => handleRun(selectedFrames, audioSelection)}
          disabled={!isButtonEnabled}
          style={{
            backgroundColor: isButtonEnabled ? 'blue' : 'gray',
            color: isButtonEnabled ? 'white' : 'darkgray',
            cursor: isButtonEnabled ? 'pointer' : 'not-allowed',
          }}
        >Run</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FrameSelectorModal;
import React from 'react'

const Billing = () => {
  return (
    <div>
        <h1>Billing</h1>
            <div>
                Coming soon...
            </div>
    </div>
  )
}

export default Billing
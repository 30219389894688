import React, { useState } from 'react';
import axios from 'axios';

const MessageForm = () => {
  const [message, setMessage] = useState('');
  const [phoneNumbers, setPhoneNumbers] = useState('');
  const [file, setFile] = useState(null);

  const handleFileUpload = (e) => {
    setFile(e.target.files[0]);
  };

  const parseCSV = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const text = reader.result;
        const numbers = text.split('\n').map(line => line.trim()).filter(line => line);
        resolve(numbers);
      };
      reader.onerror = reject;
      reader.readAsText(file);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let numbers = phoneNumbers.split(',')
      .map(num => num.trim())
      .filter(num => /^[0-9]+$/.test(num));
    numbers = [...new Set(numbers)]; // Ensure unique numbers

    if (file) {
      const fileNumbers = await parseCSV(file);
      const validFileNumbers = fileNumbers
        .map(num => num.replace(/[^0-9]/g, '')) // Remove non-numeric characters
        .filter(num => /^[0-9]+$/.test(num));
      numbers = [...new Set([...numbers, ...validFileNumbers])]; // Merge and ensure unique
      setPhoneNumbers(numbers.join(',')); // Update phone numbers text box
    }

    try {
      await axios.post('/api/whatsapp/send-message', { message, phoneNumbers: numbers });
      alert('Message sent successfully');
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Error sending message');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Message:</label>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rows="5" // Increase rows to make the text area larger
          cols="50" // Optional: Adjust width as needed
          required
        />
      </div>
      <div>
        <label>Phone Numbers (comma-separated):</label>
        <input
          type="text"
          value={phoneNumbers}
          onChange={(e) => setPhoneNumbers(e.target.value)}
        />
      </div>
      <div>
        <label>Upload CSV:</label>
        <input type="file" accept=".csv" onChange={handleFileUpload} />
      </div>
      <button type="submit">Send Message</button>
    </form>
  );
};

function WhatsappMessageSender() {
  return (
    <div className="App">
      <h1>Send Whatsapp Message</h1>
      <MessageForm />
    </div>
  );
}

export default WhatsappMessageSender;
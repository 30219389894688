import "./ModalRoot.css";
import { useState } from "react";
import Switch from "react-switch";
import CustomModal from "./components/Modal/Modal";
import { DAYS, EVERY, FREQUENTLY } from "./utils/contants";
import Day from "./components/Day";
import Calendar from "./components/Calendar/Calendar";
import Days from "./components/Days/Days";

function PeriodicSearchModal({modalIsOpen, closeModal}) {
  // const [showModal, setShowModal] = useState(false);
  const [weekDays, setWeekDays] = useState(false);
  const [data, setData] = useState({ email: "", frequently: FREQUENTLY[0], day: "", count: 2 });
  const [months, setMonths] = useState([]);
  const [days, setDays] = useState([]);

  const handleChange = e => {
    const { name, value } = e.target;
    setData(prev => ({ ...prev, [name]: value }));
  };

  return (
    <>
      {modalIsOpen && (
        <CustomModal
          title="Frequency"
          onHide={() => closeModal()}
        >
          <form onSubmit={e => e.preventDefault()}>
            <div className="input_wrapper">
              <label>Email Address</label>
              <input
                type="email"
                name="email"
                value={data.email}
                onChange={handleChange}
                placeholder="Enter your email address"
              />
            </div>
            <div className="input_wrapper">
              <label>Frequently</label>
              <select
                name="frequently"
                value={data.frequently}
                onChange={handleChange}
                style={{ textTransform: "capitalize" }}
              >
                {FREQUENTLY.map((itm, idx) => (
                  <option
                    key={idx}
                    value={itm}
                    style={{ textTransform: "capitalize" }}
                  >
                    {itm}
                  </option>
                ))}
              </select>
            </div>
            <div className="input_wrapper">
              <label>Every</label>
              <span style={{ color: "#FC4922" }}>
                {data?.frequently !== "daily" && data.count} {EVERY[data.frequently]}
                {data?.count > 1 && data?.frequently !== "daily" && "s"}
              </span>
            </div>

            {data?.frequently === "daily" && (
              <Day
                valued={data?.day}
                handleChange={handleChange}
              />
            )}

            {data?.frequently !== "daily" && (
              <div className="input_wrapper">
                <label>{EVERY[data.frequently]}s</label>
                <input
                  type="number"
                  name="count"
                  value={data.count}
                  onChange={handleChange}
                  style={{ maxWidth: 100 }}
                />
              </div>
            )}

            {data?.frequently === "weekly" && (
              <div className="input_wrapper">
                <label>Days</label>
                <Days
                  days={days}
                  setDays={setDays}
                />
              </div>
            )}

            {data?.frequently === "yearly" && (
              <Calendar
                months={months}
                setMonths={setMonths}
              />
            )}

            {["monthly", "yearly"].includes(data?.frequently) && (
              <>
                <div
                  className="input_wrapper"
                  style={{ border: 0, paddingBottom: 0 }}
                >
                  <label>Days of week</label>
                  <Switch
                    onChange={setWeekDays}
                    checked={weekDays}
                  />
                </div>
                {weekDays && (
                  <div className="input_wrapper">
                    <div
                      className="input_wrapper"
                      style={{ border: 0, padding: 0 }}
                    >
                      <label>Select</label>
                      <select>
                        <option>First</option>
                        <option>Second</option>
                        <option>Third</option>
                        <option>Fourth</option>
                      </select>
                    </div>
                    <div
                      className="input_wrapper"
                      style={{ border: 0, padding: 0 }}
                    >
                      <label>Select Day</label>
                      <select
                        name="day"
                        value={data.day}
                        onChange={handleChange}
                      >
                        <option value="">Select Day</option>
                        {DAYS.map((itm, idx) => (
                          <option
                            key={idx}
                            value={itm}
                          >
                            {itm}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
              </>
            )}

            <div className="freq_btn_wrapper">
              <button
                className="btn_primary btn_sec"
                onClick={() => closeModal()}
              >
                Cancel
              </button>
              <button className="btn_primary">Create</button>
            </div>
          </form>
        </CustomModal>
      )}
    </>
  );
}

export default PeriodicSearchModal;

import React from 'react';

function FileSelectorButton({onFileSelect,
                             buttonText = "Choose File", className = "",
}) {
  // Function to simulate a click on the file input when the button is clicked
  const handleButtonClick = () => {
    document.getElementById('file-input').click();
  };

  // Function to handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // Use callback function to handle the selected file
    if (file && onFileSelect) {
        return onFileSelect(file);
    }
  };

  return (
    <div>
      {/* Hidden file input */}
      <input
        type="file"
        id="file-input"
        style={{ display: 'none' }}
        accept="image/*,video/*"
        onChange={handleFileChange}
      />

      {/* Button that triggers the file input */}
      <button className={className} onClick={handleButtonClick}>
        {buttonText}
      </button>
    </div>
  );
}

export default FileSelectorButton;

import React, { useState, useRef, useEffect } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import "../styles/audio.css";

const AudioPlayer = ({ audioData, setAudioSelectionCb, maxAudioDuration }) => {
  const [audioSrc, setAudioSrc] = useState(null);
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [warning, setWarning] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => {
    if (audioData) {
      setAudioSrc(audioData);
      const audio = new Audio(audioData);
      audio.onloadedmetadata = () => {
        const endTime = Math.min(audio.duration, maxAudioDuration);
        setDuration(audio.duration);
        // set end time to min of maxAudioDuration and audio duration
        setEndTime(endTime);
        setAudioSelectionCb({start:0, end: endTime})
      };
    }
  }, [audioData]);

  const handleSliderChange = (values) => {
    const [start, end] = values;
    if (end - start > maxAudioDuration) {
      setWarning(true);
    } else {
      setWarning(false);
      setStartTime(start);
      setEndTime(end);
      setAudioSelectionCb({start, end})
    }
  };

  const handlePlaySegment = () => {
    if (audioRef.current) {
      audioRef.current.audioEl.current.currentTime = startTime;
      audioRef.current.audioEl.current.play();
      setTimeout(() => {
        audioRef.current.audioEl.current.pause();
      }, (endTime - startTime) * 1000);
    }
  };

  return (
    <div className="audio-player">
      {audioSrc && (
        <>
          <ReactAudioPlayer src={audioSrc} controls ref={audioRef} />
          <div className="segment-controls">
            <Slider
              range
              min={0}
              max={duration}
              defaultValue={[startTime, endTime]}
              onChange={handleSliderChange}
              step={0.01}
            />
            <div className="time-display">
              <div>Start Time: {startTime.toFixed(2)}s</div>
              <div>End Time: {endTime.toFixed(2)}s</div>
            </div>
            {warning && <div className="warning">{`Segment duration cannot exceed ${maxAudioDuration} seconds`}.</div>}
            <button className="play-select-button" onClick={handlePlaySegment}>Play Segment</button>
          </div>
        </>
      )}
    </div>
  );
};

export default AudioPlayer;
export const FREQUENTLY = ["daily", "weekly", "monthly", "yearly"];
export const DAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const EVERY = {
  daily: "Day",
  weekly: "Week",
  monthly: "Month",
  yearly: "Year",
};

import "./Modal.css";
import { useEffect, useRef } from "react";
import closeIcon from "../../assets/close.svg";

const CustomModal = ({ children, title, onHide }) => {
  const modalRef = useRef();
  useEffect(() => {
    let handler = e => {
      if (!modalRef.current.contains(e.target)) {
        onHide();
      }
    };

    document.addEventListener("mousedown", handler);
    return () => document.removeEventListener("mousedown", handler);
  }, [onHide]);

  return (
    <>
      <div className="custom_overlay"></div>
      <div
        ref={modalRef}
        className="custom_modal__cont"
      >
        <div className="custom_modal__header">
          <h2>{title}</h2>
          <img
            onClick={onHide}
            src={closeIcon}
            alt="Close"
          />
        </div>
        <div className="custom_modal__body">{children}</div>
      </div>
    </>
  );
};

export default CustomModal;

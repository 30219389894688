import { useState } from "react";
import "./SearchResultsSection.css";
import Cards from "./Components/Cards";
import SearchResults from "../../components/SearchResults";

function SearchResultsSection({searchResults, showMediaValidation, mediaAnalysisResults, imageUrl}) {
  const [analysisView, setAnalysisView] = useState('Base');
  const [filterString, setFilterString] = useState('');
  const [selectedTab, setSelectedTab] = useState('visual');

  const onVisualSearchTabClick = () => {
    setSelectedTab('visual');
  }

  const onAnalysisSearchTabClick = () => {
    setSelectedTab('analysis');
  }

  return (
    <div className="App">
      <div className="main-container">
        <div className="tab-wrapper">
          <div className={`tab${selectedTab === 'visual' ? ' selected' : ''}`} onClick={onVisualSearchTabClick}>Visual Search</div>
          <div className={`tab${selectedTab === 'analysis' ? ' selected' : ''}`}onClick={onAnalysisSearchTabClick}>Analysis</div>
        </div>
        <SearchResults searchResults={searchResults}
        filterString={filterString} setFilterString={setFilterString} className={selectedTab !== 'visual' ? 'mobile-hide' : ''}/>
        {showMediaValidation &&
        <div className={`right-container${selectedTab !== 'analysis' ? ' mobile-hide' : ''}`}>
          {analysisView==='Base' && 
            <Cards setAnalysisView={setAnalysisView} mediaAnalysisResults={mediaAnalysisResults}
            setFilterString={setFilterString}
            />
          }
        </div>
        }
      </div>
    </div>
  );
}

export default SearchResultsSection;

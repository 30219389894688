import { FaRegCheckCircle } from "react-icons/fa";

export const cards = [
  {
    icon: <FaRegCheckCircle className="colored-box-icon" />,
    title: "Source Reliability",
    buttonText: "Click for analysis",
    enabled: true,
    customBottonText: true,
    searchFilterAction: true,
  },
  {
    icon: <FaRegCheckCircle className="colored-box-icon" />,
    title: "Metadata Extraction",
    buttonText: "Click for info",
    enabled: true,
    linkAction: true,
    alwaysValid: true,
    isUsingModal: true,
    notFoundButtonText: "No metadata found",
  },
  {
    icon: <FaRegCheckCircle className="colored-box-icon" />,
    title: "Visual Inspection",
    buttonText: "Click for info",
    enabled: true,
    linkAction: true,
    alwaysValid: true,
    isUsingModal: true,
  },
  {
    icon: <FaRegCheckCircle className="colored-box-icon" />,
    title: "Generative AI",
    buttonText: "Click for info",
    enabled: true,
    linkAction: true,
    isUsingModal: true
  },
  {
    icon: <FaRegCheckCircle className="colored-box-icon" />,
    title: "Manual Manipulation",
    buttonText: "Click for info",
    notFoundButtonText: "Coming Soon",
    enabled: true,
    linkAction: true,
    isUsingModal: true
  },
  {
    icon: <FaRegCheckCircle className="colored-box-icon" />,
    title: "Audio & Sound Analysis",
    buttonText: "Click for analysis",
    notFoundButtonText: "No audio found",
    enabled: true,
    customBottonText: true,
  },
  {
    icon: <FaRegCheckCircle className="colored-box-icon" />,
    title: "Time Validation",
    buttonText: "Click for analysis",
    enabled: true,
    customBottonText: true,
    searchFilterAction: true,
  },
  {
    icon: <FaRegCheckCircle className="colored-box-icon" />,
    title: "Location Validation",
    buttonText: "Coming Soon",
    enabled: false,
  },
  {
    icon: <FaRegCheckCircle className="colored-box-icon" />,
    title: "Debunked Search",
    buttonText: "Coming Soon",
    enabled: false,
  },
  // {
  //   icon: <FaRegCheckCircle className="colored-box-icon" />,
  //   title: "Fact Validation",
  //   buttonText: "Coming Soon",
  //   enabled: false,
  // },
];

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IoClose } from "react-icons/io5";

import './Modal.css'
import { selectModalData } from '../../store/App/selectors';
import { closeModal } from '../../store/App/slice';
import { MODAL_DEFAULT_WIDTH, modalConfig } from './ModalConfig'

const emptyStateModalConfig = {
  Component: null,
  shouldCloseOnOverlayClick: true,
  hasCloseBtn: true,
  onClose: null,
  width: MODAL_DEFAULT_WIDTH
}

const Modal = () => {
  const dispatch = useDispatch()
  const modalData = useSelector(selectModalData)

  const { type, payload } = modalData || {}

  const modalInfo = type && modalConfig[type]

  const { Component, onClose } = modalInfo || emptyStateModalConfig
  const { props } = payload || {};

  const handleCloseBtnClick = async () => {
    if (onClose) {
      await onClose()
    }

    dispatch(closeModal())
  }

  return modalInfo ? <div className='modal-wrapper'>
    <div className='modal-container'>
      <div className='modal-top-bar'>
        <IoClose className="close-icon" size={18} onClick={() => handleCloseBtnClick()} />
      </div>
      <Component {...props} />
    </div>
  </div> : null;
}

export default Modal

import GenerationAndManipulation from './Modals/GenerationAndManipulation'
import VisualInpection from './Modals/VisualInspection'
import { ModalTypes } from '../../constants/ModalTypes'
import MetadataTable from "./Modals/MetadataTable";

export const MODAL_DEFAULT_WIDTH = 470

export const modalConfig = {
  [ModalTypes.GENERATION_AND_MANIPULATION]: {
    Component: GenerationAndManipulation,
    shouldCloseOnOverlayClick: false,
    hasCloseBtn: true,
    onClose: null,
    width: MODAL_DEFAULT_WIDTH
  },
  [ModalTypes.VISUAL_INSPECTION]: {
    Component: VisualInpection,
    shouldCloseOnOverlayClick: false,
    hasCloseBtn: true,
    onClose: null,
    width: MODAL_DEFAULT_WIDTH
  },
  [ModalTypes.METADATA_EXTRACTION]: {
    Component: MetadataTable,
    shouldCloseOnOverlayClick: false,
    hasCloseBtn: true,
    onClose: null,
    width: MODAL_DEFAULT_WIDTH,
  }
}

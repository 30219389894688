import { IoCheckmarkCircle } from "react-icons/io5";
import { GoAlertFill } from "react-icons/go";
import { useState } from "react";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import "../../../components/SearchResultsSection/Components/Analysis/Analysis.css";
import "./GenerationAndManipulation.css"
import ImageZoomPan from "./../../imageViewer";

const GenerationAndManipulation = ({ mediaAnalysisResults, analysisName}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : mediaAnalysisResults.result.results.length - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex < mediaAnalysisResults.result.results.length - 1 ? prevIndex + 1 : 0));
  };

  function getExplanation(mediaAnalysisResults) {
    const analysisData = mediaAnalysisResults.result.results[currentIndex].result
    if (mediaAnalysisResults.result.results[currentIndex].analysisType === "Image Gen-AI Analysis") {
      const manipulationPercent = Math.round(Math.max(analysisData.deepFakeConfidence, analysisData.genAiConfidence) * 100);
      const engineStr = analysisData.genAiEngine ? `, Probable AI engine - ${analysisData.genAiEngine}` : '';
      return `AI-Generated - ${manipulationPercent}%${engineStr}`;
    } else if (mediaAnalysisResults.result.results[currentIndex].analysisType === "Image Manipulation Analysis") {
      const photoshopPercent = `${Math.round(analysisData.manipulationConfidence * 100)}%`;
      return `Image Manipulated - ${photoshopPercent}`;
    }
    else {
      return analysisData.analysisType;
    }
  }

  function getCardObject(mediaAnalysisResults) {
    return mediaAnalysisResults.isValid ?
      <IoCheckmarkCircle className="colored-box-icon" size={18} /> :
      <GoAlertFill className="colored-box-icon-red-icon" size={18} />;
  }

  const isValid = mediaAnalysisResults.result.results[currentIndex].result.isValid;
  let currentImage = mediaAnalysisResults.result.results[currentIndex].image;
  const analysisTitle = mediaAnalysisResults.result.results[currentIndex].analysisType;

  return (
    <div className="analysis-container">
      <div className={isValid ? "analysis-valid" : "analysis-alert"}>
        <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
          {getCardObject(mediaAnalysisResults)}
          <span style={{ color: isValid ? "#3F8A63" : "#FE3B30", fontWeight: 600 }}>
            {analysisName}
          </span>
        </div>
        <Chip label={analysisTitle} style={{ borderRadius: '6px', backgroundColor: '#FFD700', color: '#000' }} />
      </div>
      <div className="image-navigation">
          <button
            style={{ marginTop: 0, alignItems: "center", fontSize: "16px" }}
            className={`navigation-label ${isValid ? "green-button" : "red-button"}`}
          >
            {getExplanation(mediaAnalysisResults)}
          </button>
        <Stack direction="row" spacing={1} className="chip-stack" justifyContent="flex-end" style={{marginBottom: "8px"}}>
          <Chip label="Previous" onClick={handlePrev} style={{ borderRadius: '6px', backgroundColor: '#FFD700', color: '#000' }} />
          <Chip label="Next" onClick={handleNext} style={{ borderRadius: '6px', backgroundColor: '#FFD700', color: '#000' }} />
        </Stack>
        <div className="image-container">
          <ImageZoomPan imageSrc={currentImage} />
        </div>
      </div>
    </div>
  );
};

export default GenerationAndManipulation;
import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import AsyncSelect from 'react-select/async';
import { Country, State, City } from 'country-state-city';

const ContextInputModal = ({ open, onClose, articleClaim, setMediaContext}) => {
  const [claim, setClaim] = useState('');
  const [time, setTime] = useState(new Date().toISOString().slice(0, 16));
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  // add useEffect to load claim from articleClaim when modal is opened
  useEffect(() => {
    if (articleClaim?.claim) {
      setClaim(articleClaim.claim);
      // setTime(articleClaim.time);
      // setSelectedLocation(articleClaim.location);
    }
  }, [articleClaim]);

  useEffect(() => {
    const countries = Country.getAllCountries().map(country => ({
      label: country.name,
      value: country.isoCode,
      type: 'country'
    }));

    const states = State.getAllStates().map(state => ({
      label: state.name,
      value: state.isoCode,
      type: 'state'
    }));

    const cities = City.getAllCities().map(city => ({
      label: city.name,
      value: city.name,
      type: 'city'
    }));

    setLocationOptions([...countries, ...states, ...cities]);
  }, []);

  const filterLocations = (inputValue) => {
    return locationOptions.filter(option =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadOptions = (inputValue, callback) => {
    if (inputValue.length >= 3) {
      setTimeout(() => {
        callback(filterLocations(inputValue));
      }, 1000);
    } else {
      callback([]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ claim, time, selectedLocation });
    onClose();
  };

  const closeModal = () => {
    setMediaContext(null)
    setClaim('');
    setTime(new Date().toISOString().slice(0, 16));
    setSelectedLocation(null);
    onClose();
  }
  const submitModal = () => {
    let selectedContext = {claim}
    if (time) {selectedContext.time = time;}
    if (selectedLocation?.label) {selectedContext.location = selectedLocation.label;}
    setMediaContext(selectedContext)
    console.log(selectedContext);
    onClose();
  }
  return (
    <Dialog open={open} onClose={closeModal}>
      <DialogTitle>Enter Context Details</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Claim"
            fullWidth
            value={claim}
            onChange={(e) => setClaim(e.target.value)}
            required
            margin="normal"
          />
          <TextField
            label="Time"
            type="datetime-local"
            fullWidth
            value={time}
            onChange={(e) => setTime(e.target.value)}
            required
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <AsyncSelect
            loadOptions={loadOptions}
            onChange={setSelectedLocation}
            placeholder="Enter a location"
            isClearable
          />
          <DialogActions>
            <Button onClick={closeModal}>Cancel</Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={submitModal}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ContextInputModal;
import React, { useState } from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import '../styles/SearchResults.css';

const SearchHitRow = ({ searchHit }) => {
  const sourceTag = searchHit.domain_reliability;
  const sourceColor = sourceTag === 'high' ? '#C2F5E4' : sourceTag === 'medium' ? '#FFD700' : '#FE3B3026';
  const sourceDisplayTag = sourceTag === 'social' ? sourceTag : `Reliability:${sourceTag}`;
  const claimTag = searchHit.claim_supported;
  const claimColor = claimTag === 'supported' ? '#C2F5E4' : claimTag === 'irrelevant' ? '#FFD700' : '#FE3B3026';
  const dateColor = '#ADD8E6'; // light blue
  const fakeColor = '#FE3B3026'; // Red
  const similarityTag = searchHit.similarity ? 'Similarity:' + (searchHit.similarity * 100).toFixed(0) + '%' : undefined;
  const similarColor = !searchHit.similarity ? undefined : searchHit.similarity > 0.9 ? '#C2F5E4' : searchHit.similarity > 0.7 ? '#FFD700' : '#FE3B3026';

  if (searchHit.datetime) {
    try {
      const date = new Date(searchHit.datetime);
      searchHit.datetime = date.toISOString().split('T')[0];
    } catch (error) {
      searchHit.datetime = undefined;
    }
  }

  return (
    <li className="video-item" key={searchHit.id}>
      <img src={searchHit.thumbnail} alt="Video thumbnail" className="video-thumbnail" />
      <img src={searchHit.source_icon} alt="Video thumbnail" className="icon-thumbnail" />
      <a href={searchHit.link} className="video-link" target="_blank" rel="noreferrer">
        {searchHit.title}
      </a>
      <Stack direction="column" spacing={1} className="chip-stack">
        {searchHit.domain_reliability && <Chip label={sourceDisplayTag} style={{ borderRadius: '6px', backgroundColor: sourceColor, color: '#000' }} />}
        {searchHit.claim_supported && <Chip label={`Claim:${claimTag}`} style={{ borderRadius: '6px', backgroundColor: claimColor, color: '#000' }} />}
        {searchHit.fake_indicated && <Chip label="Fake?" style={{ borderRadius: '6px', backgroundColor: fakeColor, color: '#000' }} />}
        {searchHit.datetime && <Chip label={searchHit.datetime} style={{ borderRadius: '6px', backgroundColor: dateColor, color: '#000' }} />}
        {searchHit.similarity && <Chip label={similarityTag} style={{ borderRadius: '6px', backgroundColor: similarColor, color: '#000' }} />}
      </Stack>
    </li>
  );
};

function SearchResults({ searchResults, filterString, setFilterString, className }) {

  const handleFilterChange = (event) => {
    setFilterString(event.target.value);
  };

  const filteredResults = searchResults.filter((hit) => {
    if (filterString === "label::fakeIndicated") {
      return hit.fake_indicated===true;
    } else if (filterString === "label::showDatesOnly") {
      return hit.datetime;
    } else {
      return hit.title?.toLowerCase().includes(filterString.toLowerCase())
    }
  }
  );

  const indexedSearchResults = filteredResults.map((hit, index) => ({ ...hit, id: index + 1 }));

  return (
    <div className={`videos-search ${className || ''}`}>
      <div className="videos-search-header">
        <h3 className="visual-search-title">Visual Search</h3>
        <p>Found {indexedSearchResults.length} relevant results</p>
        <input
          type="search"
          className="videos-search-input"
          placeholder="Search..."
          value={filterString}
          onChange={handleFilterChange}
        />
      </div>
      <div className="videos-list">
        <ul className="video-item-wrapper">
          {indexedSearchResults.map((hit) => (
            <SearchHitRow key={hit.id} searchHit={hit} />
          ))}
        </ul>
      </div>
    </div>
  );
}

export default SearchResults;
import React from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const ImageZoomPan = ({ imageSrc }) => {
  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <TransformWrapper
        defaultScale={1}
        defaultPositionX={0}
        defaultPositionY={0}
        wheel={{ step: 0.1 }}
        doubleClick={{ disabled: true }}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
            <TransformComponent>
              <img 
                src={imageSrc} 
                alt="Zoomable" 
                style={{ 
                  width: '100%', 
                  height: 'auto', 
                  imageRendering: 'pixelated' 
                }} 
              />
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>
    </div>
  );
};

export default ImageZoomPan;
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ItemForm = ({ fetchItems, itemToEdit, setItemToEdit }) => {
  const [urlOrigin, setUrlOrigin] = useState('');
  const [type, setType] = useState('image');
  const [manipulationType, setManipulationType] = useState('image-ai-generated');
  const [comments, setComments] = useState('');

  useEffect(() => {
    if (itemToEdit) {
      setUrlOrigin(itemToEdit.urlOrigin);
      setType(itemToEdit.type);
      setManipulationType(itemToEdit.manipulationType);
      setComments(itemToEdit.comments);
    }
  }, [itemToEdit]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const item = { urlOrigin, type, manipulationType, comments };

    if (itemToEdit) {
      await axios.put(`/api/knownfake/${itemToEdit.id}`, item);
    } else {
      await axios.post('/api/knownfake/', item);
    }

    setUrlOrigin('');
    setType('image');
    setManipulationType('image-ai-generated');
    setComments('');
    setItemToEdit(null);
    fetchItems();
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={urlOrigin}
        onChange={(e) => setUrlOrigin(e.target.value)}
        placeholder="URL Origin"
        required
      />
      <select
        value={type}
        onChange={(e) => setType(e.target.value)}
        required
      >
        <option value="image">Image</option>
        <option value="video">Video</option>
        <option value="article">Article</option>
      </select>
      <select
        value={manipulationType}
        onChange={(e) => setManipulationType(e.target.value)}
        required
      >
        <option value="image-ai-generated">Image AI-Gen</option>
        <option value="audio-ai-generated">Audio AI-Gen</option>
        <option value="image-audio-ai-generated">Image&Audio AI-Gen</option>
        <option value="image-edited">Image Edited</option>
        <option value="audio-edited">Audio Edited</option>
        <option value="image-audio-edited">Image&Audio Edited</option>
      </select>
      <input
        type="text"
        value={comments}
        onChange={(e) => setComments(e.target.value)}
        placeholder="Comments"
      />
      <button type="submit">Submit</button>
    </form>
  );
};

export default ItemForm;
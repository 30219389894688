import { IoCheckmarkCircle } from "react-icons/io5";
import { useState } from "react";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import "../../../components/SearchResultsSection/Components/Analysis/Analysis.css";
import "./VisualInspection.css"
import ImageZoomPan from "./../../imageViewer";

const VisualInspection = ({ mediaAnalysisResults, analysisName}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentLabel, setCurrentLabel] = useState("Original");
  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : mediaAnalysisResults.result.length - 1));
  };
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex < mediaAnalysisResults.result.length - 1 ? prevIndex + 1 : 0));
  };
  const setOriginal = () => {setCurrentLabel("Original")}
  const setEla = () => {setCurrentLabel("Error Level Analysis")}
  const setGrad = () => {setCurrentLabel("Image Edge Analysis")}
  const setManipulation = () => {setCurrentLabel("Visual Manipulation")}

  function getExplanation(label) {
    if (label==="Original") {
        return "Original image.";
    } else if (label==="Error Level Analysis") {
        return "Regions much lighter/darker than the surrounding are possiblly manipulated.";
    } else if (label==="Copy Move Analysis") {
        return "Marked areas might be duplicates.";
      } else if (label==="Sources Merge Analysis") {
        return "Dark or bright areas are possibly merged from a different image.";
      } else if (label==="Image Edge Analysis") {
        return "Inconsistent lines and areas are possibly manipulated.";
      }else if (label==="Visual Manipulation") {
        return "Highlighted areas are possibly manipulated.";
      }
    else {
      return '';
    }
  }

  function getCardObject(mediaAnalysisResults) {
    return <IoCheckmarkCircle className="colored-box-icon" size={18} />
  }

  const isValid = true;
  const currentFilter = mediaAnalysisResults.result[currentIndex].find(el => el.filterType===currentLabel);
  let currentImage = currentFilter?.image;
  const analysisTitle = currentLabel;

  return (
    <div className="analysis-container">
      <div className={isValid ? "analysis-valid" : "analysis-alert"}>
        <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
          {getCardObject(mediaAnalysisResults)}
          <span style={{ color: isValid ? "#3F8A63" : "#FE3B30", fontWeight: 600 }}>
            {analysisName}
          </span>
        </div>
        <Chip label={analysisTitle} style={{ borderRadius: '6px', backgroundColor: '#FFD700', color: '#000' }} />
      </div>
      <div className="image-navigation">
          <button
            style={{ marginTop: 0, alignItems: "center", fontSize: "16px" }}
            className={`navigation-label ${isValid ? "green-button" : "red-button"}`}
          >
            {getExplanation(currentLabel)}
          </button>
        <Stack direction="row" spacing={1} className="chip-stack" justifyContent="flex-end" style={{marginBottom: "8px"}}>
          <Chip label="Original" onClick={setOriginal} style={{ borderRadius: '6px', backgroundColor: '#ADD8E6', color: '#000' }} />
          <Chip label="Error Level" onClick={setEla} style={{ borderRadius: '6px', backgroundColor: '#ADD8E6', color: '#000' }} />
          <Chip label="Gradient" onClick={setGrad} style={{ borderRadius: '6px', backgroundColor: '#ADD8E6', color: '#000' }} />
          {/* <Chip label="Photoshop" onClick={setManipulation} style={{ borderRadius: '6px', backgroundColor: '#ADD8E6', color: '#000' }} /> */}

          <Chip label="Previous" onClick={handlePrev} style={{ borderRadius: '6px', backgroundColor: '#FFD700', color: '#000' }} />
          <Chip label="Next" onClick={handleNext} style={{ borderRadius: '6px', backgroundColor: '#FFD700', color: '#000' }} />
        </Stack>
        <div className="image-container">
          <ImageZoomPan imageSrc={currentImage} />
        </div>
      </div>
    </div>
  );
};

export default VisualInspection;
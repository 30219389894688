import React from 'react';

const ItemList = ({ items, editItem, deleteItem }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>URL Origin</th>
          <th>Type</th>
          <th>Manipulation Type</th>
          <th>Create Time</th>
          <th>Comments</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => (
          <tr key={item.id}>
            <td>{item.url_origin}</td>
            <td>{item.type}</td>
            <td>{item.manipulation_type}</td>
            <td>{new Date(item.created_time).toISOString()}</td>
            <td>{item.comments}</td>
            <td>
              <button onClick={() => editItem(item)}>Edit</button>
              <button onClick={() => deleteItem(item.id)}>Delete</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ItemList;
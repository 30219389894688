import React from 'react'
import axios from 'axios';
import { useState } from 'react';
import SideBar from "../components/SideBar";
import TopBar from "../components/TopBar";
import PeriodicSearchModal from "../components/PeriodicSearchModalV2/ModalRoot";
import SearchResultsSection from "../components/SearchResultsSection/SearchResultsSection";
import LoadingBackdrop from "../components/ProgressBar";
import FrameSelectorModal from "../components/VideoSelectorModal";
import ContextInputModal from "../components/ContextInput";
import '../styles/TopBar.css';
import assert from 'assert';

/* create enum for upload state */
const UploadStatusEnum = {
  Idle: 'idle',
  Uploading: 'uploading',
  Complete: 'complete',
}

const Home = (props) => {

  const [mediaUuid, setMediaUuid] = useState('');
  const [image, setImage] = useState(null);
  const [displayImageUrl, setDisplayImageUrl] = useState(null);
  const [uploadState, setUploadState] = useState(UploadStatusEnum.Idle);
  const [singleSearchResults, setSearchResults] = useState(null)
  const [showSearchResults, setShowVideosSearch] = useState(false);
  const [analysisStatus, setAnalysisStatus] = useState('idle')

  const [mediaAnalysisModalIsOpen, setMediaAnalysisModalIsOpen] = useState(false);
  const openMediaAnalysisModal = () => {setMediaAnalysisModalIsOpen(true);};
  const closeMediaAnalysisModal = () => {setMediaAnalysisModalIsOpen(false);};
  const [singleMediaAnalysisResults, setSingleMediaAnalysisResults] = useState([])
  const addItemToMediaAnalysis = (newItem) => {
    setSingleMediaAnalysisResults((prevItems) => [...prevItems, newItem]);
  };

  const [periodicSearchModalIsOpen, setPeriodicSearchIsOpen] = useState(false);
  const openPeriodicSearchModal = () => {setPeriodicSearchIsOpen(true);};
  const closePeriodicSearchModal = () => {setPeriodicSearchIsOpen(false);};

  const [frameSelectionIsOpen, setFrameSelectionIsOpen] = useState(false);
  const openFrameSelectionModal = () => {setFrameSelectionIsOpen(true);};
  const closeFrameSelectionModal = () => {setFrameSelectionIsOpen(false);};
  const [extractedFrames, setExtractedFrames] = useState(null);
  const [extractedAudio, setExtractedAudio] = useState(null);
  const [selectedFrames, setSelectedFrames] = useState([]);

  const [mediaContext, setMediaContext] = useState(null);
  const [articleClaim, setArticleClaim] = useState(null);
  const [contextInputModalIsOpen, setContextInputModalIsOpen] = useState(false);
  const openContextInputModal = () => {setContextInputModalIsOpen(true);};
  const closeContextInputModal = () => {setContextInputModalIsOpen(false);};

  const getMediaRequest = async (fileObjectOrUrl, isLocal, mediaType, metadata={}) => {
    resetSearchResults() // make sure we reset the previous search
    if (isLocal) {
      if (![UploadStatusEnum.Idle, UploadStatusEnum.Complete].includes(uploadState)) {
        return null;
      }
      setImage(fileObjectOrUrl); // set the state to formData
      setUploadState(UploadStatusEnum.Uploading);
      const uploadData = await uploadMedia(fileObjectOrUrl, mediaType);
      setMediaUuid(uploadData.uuid);
      if (['image', 'video'].includes(mediaType) && uploadData.framesData) {
        setExtractedFrames(uploadData.framesData);
        setExtractedAudio(uploadData.audioData)
        openFrameSelectionModal()
      }
      return uploadData.uuid;
    } else if (mediaType === 'article') {
      setAnalysisStatus('Extracting Media')
      // Initialize media, get back video, audio, images
      let articleResponse = await axios.post("/api/article-init", {url: fileObjectOrUrl, mediaType, metadata, user: props.user?.email});
      setAnalysisStatus('idle')
      articleResponse = articleResponse.data;
      if (!articleResponse.success) {
        return undefined;
      }
      setMediaUuid(articleResponse.uuid);
      if (articleResponse.framesData && articleResponse.framesData.length > 0) {
        // Multiple images or frames of extracted video
        setExtractedFrames(articleResponse.framesData);
        setExtractedAudio(articleResponse.audioData)
        openFrameSelectionModal()
      return articleResponse.uuid;
      } else {
        // Single image or video
        return undefined;
      }
    } else {
    // just set the uuid to the state
      let remote_init_response = await axios.post("/api/remote-media-init", {url: fileObjectOrUrl, mediaType: mediaType, user: props.user?.email});
      setMediaUuid(remote_init_response.data.uuid);
      if (['image', 'video'].includes(mediaType) && remote_init_response.framesData) {
        setExtractedFrames(remote_init_response.framesData);
        setExtractedAudio(remote_init_response.audioData)
        openFrameSelectionModal()
      }
      return remote_init_response.data.uuid;
    }
  }

  async function uploadMedia(fileObject, mediaType) {
    // let dummy_response = await axios.post("/api//whatsapp-webhook", {});
    try {
      assert(['image', 'video'].includes(mediaType), 'Invalid media type. Must be either image or video.');
      const formData = new FormData();
      formData.append(mediaType, fileObject);
      formData.append('user', props.user?.email)
      setAnalysisStatus('Processing Media')
      const res = await axios.post('/api/media-upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Axios response: ', res);
      setUploadState(UploadStatusEnum.Complete);
      setMediaUuid(res.data.uuid);
      setAnalysisStatus('idle')
      return res.data;
    } catch (error) {
      console.error(error);
    }
      return null;
  }

  const resetSearchResults = () => {
      closeMediaAnalysisModal() // make sure we reset the previous search
      setSearchResults(null);
      setShowVideosSearch(false);
      setSingleMediaAnalysisResults([])
      setMediaUuid('');
  }
  
  const handleSearchRequest = async (searchUuid, context = null, frames = []) => {
  try {
    setAnalysisStatus('Searching');
    
    let search_response = await axios.post("/api/media-search", {
      uuid: searchUuid,
      frames: frames,
    });
    
    let searchResults = search_response.data.imageSearchResults;
    setSearchResults(searchResults.flat());
     
    // Make additional API calls without waiting for them to complete
    setAnalysisStatus('Extracting Metadata');
    setShowVideosSearch(true);
    const mediaMetadataPromise = axios.post("/api/media-metadata", { 
      data: searchResults, extractMetadata: true, extractDomainMetrics: true,
      context: context});
    const mediaSimilarityPromise = axios.post("/api/media-similarity", {
      data: searchResults, uuid: searchUuid, frames: frames, });

    // Wait for both promises to resolve
    const [metadataResponse, similarityResponse] = await Promise.all([mediaMetadataPromise, mediaSimilarityPromise]);
    // Update search_response with metadata
    if (metadataResponse.data && metadataResponse.data.imageSearchResultsWithMetadata) {
      searchResults = metadataResponse.data.imageSearchResultsWithMetadata;
      // Update your state or handle the updated metadata as needed
      console.log('Updated metadata:', searchResults);
    }

    // Update search_response with similarity results
    if (similarityResponse.data && similarityResponse.data.similarity) {
      // iterate on similarity and add to each search result similarity field
      searchResults.forEach((result, index) => {
        result.similarity = similarityResponse.data.similarity[index];
      });
    }

    // Optionally update the UI or state with the modified search_response
    setAnalysisStatus('idle');
    setSearchResults(searchResults);

    // Call finalize search results with aggregated metadata
    const aggregateResult = await axios.post("/api/aggregate-metadata", { 
      data: searchResults, uuid: searchUuid, context: context
    });
    setSearchResults(aggregateResult.data.finalSearchResults);

    // Update aggregated metadata results
    if (aggregateResult.data.metadataAnalysis && aggregateResult.data.metadataAnalysis.length > 0) {
      for (const result of aggregateResult.data.metadataAnalysis) {
        addItemToMediaAnalysis(result);
      }
    }


  } catch (error) {
    console.log(error);
    resetSearchResults(); // make sure we reset the previous search
  }
};

  const updateAnalysisResults = async (analysis_response) => {
    if (analysis_response.analysis.visualGenAi) {
      addItemToMediaAnalysis({analysisType:"Generative AI", result: analysis_response.analysis.visualGenAi});
    }
    if (analysis_response.analysis.visualManipulation) {
      addItemToMediaAnalysis({analysisType:"Manual Manipulation", result: analysis_response.analysis.visualManipulation});
    }
    if (analysis_response.analysis.audio) {
      addItemToMediaAnalysis({analysisType:"Audio & Sound Analysis", result: analysis_response.analysis.audio});
    }
    if (analysis_response.analysis.visualFilters) {
      addItemToMediaAnalysis({analysisType:"Visual Inspection", result: analysis_response.analysis.visualFilters});
    }
    if (analysis_response.metadata) {
      addItemToMediaAnalysis({analysisType:"Metadata Extraction", result: analysis_response.metadata});
    }
  }
  
  const jointSearchAnalyzeVideo = async (frames, audio) => {

      setAnalysisStatus('Analyzing Media');
      let analysis_response = await axios.post("/api/media-analysis", { uuid: mediaUuid, frames:frames, audio:audio});
      analysis_response = analysis_response.data.result;
      await updateAnalysisResults(analysis_response);
      openMediaAnalysisModal();
      setSearchResults([]) // empty results
      setShowVideosSearch(true);
      await handleSearchRequest(mediaUuid, mediaContext, frames);
      setAnalysisStatus('idle');
  }

  return (
    <>
      <TopBar class-name='top-bar'/>
      <div className="main-container">
        <SideBar getMediaRequestCb={getMediaRequest}
          openPeriodicSearchModal={openPeriodicSearchModal}
          displayImageUrl={displayImageUrl}
          setDisplayImageUrl={setDisplayImageUrl}
          setArticleClaim={setArticleClaim}
        />
        <div className="result-container">
          {frameSelectionIsOpen && extractedFrames &&
          <FrameSelectorModal
          open={frameSelectionIsOpen} handleClose={closeFrameSelectionModal}
          runOp={jointSearchAnalyzeVideo}
          frames={extractedFrames} audio={extractedAudio}
          selectedFrames={selectedFrames} setSelectedFrames={setSelectedFrames}
          openContextInputModal={openContextInputModal}
          />}
          {/* Conditionally display VideosSearch component */}
          <LoadingBackdrop text={analysisStatus} isLoading={!analysisStatus.includes('idle', 'Extracting Media')}/>
          {showSearchResults && singleSearchResults!==null && 
          <SearchResultsSection searchResults={singleSearchResults} showMediaValidation={mediaAnalysisModalIsOpen}
                                mediaAnalysisResults={singleMediaAnalysisResults} imageUrl={displayImageUrl}/>}
          {displayImageUrl && <PeriodicSearchModal
                              modalIsOpen={periodicSearchModalIsOpen} 
                              closeModal={closePeriodicSearchModal} />}
          {/* Conditionally display Context input component */}
          {<ContextInputModal
          open={contextInputModalIsOpen} onClose={closeContextInputModal}
          articleClaim={articleClaim} setMediaContext={setMediaContext}/>}
          </div>
      </div>
    </>
  )
}

export default Home
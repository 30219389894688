import React from 'react';
import "./MetadataTable.css";
import { IoCheckmarkCircle } from "react-icons/io5";
import { useState } from "react";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const MetadataTableInternal = ({ metadata, startIndex, endIndex }) => {
  return (
    <table border="1" style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr>
          <th style={{ padding: '8px', textAlign: 'left' }}>Key</th>
          <th style={{ padding: '8px', textAlign: 'left' }}>Value</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(metadata).slice(startIndex, endIndex).map(([key, value], index) => (
          <tr key={index}>
            <td style={{ padding: '8px' }}>{key}</td>
            <td style={{ padding: '8px' }}>{String(value)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const MetadataTable = ({ mediaAnalysisResults, analysisName}) => {
  const numParamsToDisplay = 15;
  const totalParams = Object.keys(mediaAnalysisResults.result).length;
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setendIndex] = useState(numParamsToDisplay);

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - numParamsToDisplay);
      setendIndex(endIndex - numParamsToDisplay);
    }
  };

  const handleNext = () => {
    if (endIndex < totalParams) {
      setStartIndex(startIndex + numParamsToDisplay);
      setendIndex(endIndex + numParamsToDisplay);
    }
  };

  return (
    <div className="metadata-container">
      <div className="analysis-valid">
        <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
            <IoCheckmarkCircle className="colored-box-icon" size={18} />
          <span style={{ color: "#3F8A63", fontWeight: 600 }}>
            {analysisName}
          </span>
        </div>
        <Chip label="Metadata" style={{ borderRadius: '6px', backgroundColor: '#FFD700', color: '#000' }} />
      </div>
      <div className="image-navigation">
        <Stack direction="row" spacing={1} className="chip-stack" justifyContent="flex-end" style={{marginBottom: "8px"}}>
          <Chip label="Previous" onClick={handlePrev} style={{ borderRadius: '6px', backgroundColor: '#FFD700', color: '#000' }} />
          <Chip label="Next" onClick={handleNext} style={{ borderRadius: '6px', backgroundColor: '#FFD700', color: '#000' }} />
        </Stack>
        <div className="meta-image-container">
          <MetadataTableInternal metadata={mediaAnalysisResults.result} startIndex={startIndex} endIndex={endIndex}/>
        </div>
      </div>
    </div>
  );
};

export default MetadataTable;
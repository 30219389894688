import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react';
import '../styles/TopBar.css';

import aiLightImage from '../assets/images/ailight-logo.png';
// import news13Image from '../assets/images/News13.png';
import paMediaImage from '../assets/images/pa_media.png';
import reutersImage from '../assets/images/reuters.jpeg';
import defualtImage from '../assets/images/AILight-Icon.jpeg';

import LogoutIcon from '@mui/icons-material/Logout';

const getUserImage = (user) => {
  if (user?.email.includes('pa_media')) {
    return paMediaImage
  }
  else if (user?.email.includes('reueters')) {
    return reutersImage
  }
  return defualtImage;
}
const Topbar = ({ className }) => {
  const { logout } = useAuth0();
  const { user } = useAuth0();
  const userImage = getUserImage(user);
  const classNames = !!className ? `top-bar ${className}` : 'top-bar';

  return (

    <div className={classNames}>
      <Link to="/" className="ailight-logo" >
      <img src={aiLightImage} alt="AiLight Logo" />
      </Link>
      <h2 className="top-bar-subtitle">OverSee&#8482;</h2>
      {user? (
      <div className="top-bar-right">
        {/* <h2 className="user-name">  {user.nickname} </h2> */}
        <img src={userImage} alt="User" className="user-image" />
        {/* <img src={<LogoutIcon/>} alt="Logout" onClick={() => logout({ returnTo: `${window.location.origin}/login` })} className="logout-icon" /> */}
        <LogoutIcon className="logout-icon" fontSize="large" color="action"
        onClick={() => logout({ returnTo: `${window.location.origin}/login` })}/>
      </div>
      ) : (<div/>)}
    </div>
  );
}

export default Topbar
import "./Calendar.css";
import { MONTHS } from "../../utils/contants";

const Calendar = ({ months, setMonths }) => {
  const handleChange = itm => {
    setMonths(prev => (prev?.includes(itm) ? prev?.filter(m => m !== itm) : [...prev, itm]));
  };

  return (
    <div className="custom_calendar">
      {MONTHS.map((itm, idx) => (
        <div
          key={idx}
          onClick={() => handleChange(itm)}
          className={`custom_calendar_month ${months?.includes(itm) && "active"}`}
        >
          {itm}
        </div>
      ))}
    </div>
  );
};

export default Calendar;

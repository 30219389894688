import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  modal: {
    type: '',
    payload: null
}
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    openModal: (state, action) => {
        const { type, payload } = action.payload

        state.modal.type = type;
        state.modal.payload = payload;
    },
    closeModal: (state) => {
      state.modal.type = '';
      state.modal.payload = null;
  }
  },
});

export const { openModal, closeModal } = appSlice.actions;
export default appSlice.reducer;
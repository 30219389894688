import "./Days.css";
import { useState } from "react";
import { DAYS } from "../../utils/contants";
import arrow from "../../assets/arrow.svg";

const Days = ({ days, setDays }) => {
  const [show, setShow] = useState(false);

  const handleChange = itm => {
    setDays(prev => (prev?.includes(itm) ? prev?.filter(d => d !== itm) : [...prev, itm]));
  };

  return (
    <div className="days_wrapper">
      <span onClick={() => setShow(prev => !prev)}>Select Days</span>
      <img
        src={arrow}
        alt="arrow"
        style={{ transform: `rotate(${!show ? "0" : "180"}deg)` }}
      />

      {show && (
        <div className="days_cont">
          {DAYS.map((itm, idx) => (
            <div key={idx}>
              <span>{itm}</span>
              <input
                type="checkbox"
                onChange={() => handleChange(itm)}
                checked={days?.includes(itm)}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Days;

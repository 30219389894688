import React from "react";
import { DAYS } from "../utils/contants";

const Day = ({ value, handleChange }) => {
  return (
    <div className="input_wrapper">
      <label>Day</label>
      <select
        name="day"
        value={value}
        onChange={handleChange}
      >
        <option value="">Select Day</option>
        {DAYS.map((itm, idx) => (
          <option
            key={idx}
            value={itm}
          >
            {itm}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Day;
